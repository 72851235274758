
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { mask } from "vue-the-mask";
import { useRouter } from "vue-router";
import { active } from "@/core/data/genericData";

export default defineComponent({
  name: "edit-categories-modal",
  directives: { mask },
  props: {
    data: {
      type: Object,
    },
  },
  components: {},
  setup(props) {
    const active_list_data = active;
    // this.$forceUpdate();
    const router = useRouter();
    var formData = ref({});
    const store = useStore();

    const category = ref([]);
    const setCategoryData = async (data) => {
      //loading.value = true;
      try {
        //var values = {"search_term" : data}
        await store
          .dispatch(Actions.CUST_GET_PARENT_CATEGORY)
          .then(({ data }) => {
            debugger;
            category.value = data;
            console.log(category.value);
            //loading.value = false;
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const company = ref([]);
    setCategoryData("");
    const setCompanyData = async (data) => {
      //loading.value = true;
      try {
        var values = { company_id: 0, page: 1, records_per_page: 10 };
        await store
          .dispatch(Actions.CUST_GET_COMPANY_LIST, values)
          .then(({ data }) => {
            company.value = data.result_list;
            //loading.value = false;
            console.log(company.value);
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const setFormData = async (data) => {
      console.log(data.ids);
      var j = 0;
      debugger;
      try {
        var values = {
          brand_id: data.ids,
          page: 1,
          records_per_page: 1,
          search_term: "",
        };
        await store
          .dispatch(Actions.CUST_GET_COMPANY_BRAND, values)
          .then(({ data }) => {
            debugger;
            formData.value = {
              // id: data[0].brand_id,
              // name: data[0].brand_name,
              // company_select: data[0]["company"]["company_name"],
              // category_select: [],
              // active: data[0].active ? 1 : 0,
              id: data.brand_id,
              name: data.brand_name,
              short_name: data.brand_short_name,
              company_select: data.company_id,
              category_select: data.category.map((item) => {
                return item.category_id;
              }),
              // category_select: [{ active: 1 }, { active: 3 }],
              active: data.active,
            };
            console.log(formData.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    onMounted(async () => {
      debugger;
      await setFormData(props.data);
      await setCategoryData("");
      await setCompanyData(company.value);
    });

    const formRef = ref<null | HTMLFormElement>(null);
    const editBrandModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const rules = ref({
      name: [
        {
          required: true,
          message: "Brand name is required",
          trigger: "change",
        },
      ],
    });

    const setBrandData = async (data) => {
      debugger;
      //var user = JSON.parse(JwtService.getToken());
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
        brand_id: data.id,
        brand_name: data.name,
        brand_short_name: data.short_name,
        company_id: data.company_select,
        category_id: data.category_select,
        user_id: user.user_id,
        active: 1,

        // brand_id: data.id,
        // brand_name: data.name,
        // company_id: data.company_select,
        // category_id: data.category_select,
        // updated_user_id: "1",
        // updated_ip: "0.0.0.0",
        // updated_uagent: "Test",
        // active: data.active,
      };

      await store
        .dispatch(Actions.CUST_UPDATE_PRODUCT_BRAND, db_data)
        .then(({ data }) => {
          console.log(data);
          debugger;
          if (data) {
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: "Product Brand has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editBrandModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setBrandData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      setCompanyData,
      setCategoryData,
      company,
      category,
      editBrandModalRef,
      active_list_data,
    };
  },
});
