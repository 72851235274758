
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import EditProductBrandModal from "@/components/modals/forms/EditProductBrandModal.vue";
export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "apps-product-brand-details",
  components: {
   EditProductBrandModal,
    
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();

   interface ViewBrand {
      brand_id,    
      brand_name,
      comp_name,
      category_id,
      active : {
        label :string;
        color :string;
      }
    };   
  
    var resultsView = ref<ViewBrand>();
    const loadingData = ref<boolean>(true);
    const count = ref(0);

    resultsView.value = {
       brand_id :"",    
       brand_name : "",
       comp_name : "",
       category_id:"",
       active : {
        label :"",
        color :"",
      }
    }

    var paginationData = ref({})
    
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

     const refreshData =() => {      
      paginationData.value['activePage']  = 1;      
      
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
     
    }
    
    const getBrandList = async () => {
     
      try {

    var values = { "brand_id"  : props.id, "page"  : 1, "records_per_page" : 1 }

      await store.dispatch(Actions.CUST_GET_PRODUCT_BRAND, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data[0].active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }

        resultsView.value = {
          brand_id : data[0].brand_id,
          brand_name: data[0].brand_name,
          comp_name: data[0]['company']['company_name'],
          category_id: data[0].category_id,
          active: {
            label: active_label,
            color: active_color
          },
        }

      console.log(resultsView)
              

      })
      .catch(({ response }) => {

        console.log(response);

      });
        
      } catch (e) {
        console.log(e);
      }
    };        
    
    onMounted( async () => {
      await getBrandList();
      setCurrentPageBreadcrumbs("Product Brand Details", ["Product"]);
    });     


    return {
      changePageChange,
      refreshData,
      resultsView,
      loadingData,
      ids,
    };

  }
});
